// Import bootstrap and its default variables to used it globally
import "bootstrap/scss/bootstrap.scss";

// Import SASS globally
import "./src/assets/styles/scss/index.scss";

export const onRouteUpdate = ({ location }) => {
  // Track Facebook Pixel on every route change
  if (typeof window.fbq === "function" && location.pathname !== "/contact")
    window.fbq("track", "PageView");
};
